import SessionMenuOrder from '@/utils/order-information-manager/models/SessionMenuOrder'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import SessionOnlineModel from '@/utils/order-information-manager/models/SessionOnlineModel'
import { genFastOrderAutoId, genOnlineOrderAutoId } from '@/utils/id-generator'
import OnlineDelivery from '@/utils/pay-utils/models/order-models/OnlineDelivery'
import SessionViewMenuOrder from '@/utils/order-information-manager/models/SessionViewMenuOrder'
import SessionTableFastModel from '@/utils/order-information-manager/models/SessionTableFastModel'
import SessionWaitlistMenuModel from '@/utils/order-information-manager/models/SessionWaitlistMenuModel'
import SessionShareMenuModel from '@/utils/order-information-manager/models/SessionShareMenuModel'
import SessionDineInModel from '@/utils/order-information-manager/models/SessionDineInModel'
import SessionScanToPayModel from '@/utils/order-information-manager/models/SessionScanToPayModel'

function buildSessionMenuOrder(shopId: string, menuId: string, autoId: string): SessionMenuOrder {
  return {
    shopId,
    menuId,
    preOrder: OrderMenuPreOrderType.NoPreOrder,
    orderType: OrderMenuType.Menu,
    isShowBack: true,
    autoId,
    onlineDelivery: {
      deliverFee: 0,
      scheduleTime: '',
      isPickup: true,
      toStreet: '',
      toCity: '',
      toState: '',
      toZipcode: '',
      distance: 0,
      fromStreet: '',
      fromCity: '',
      fromState: '',
      fromZipcode: ''
    },
    onlineUser: {
      name: '',
      phoneNumber: ''
    }
  }
}

function buildSessionOnlineOrder(shopId: string, onlineDelivery: OnlineDelivery | undefined, isShowBack: boolean): SessionOnlineModel {
  return {
    shopId,
    preOrder: OrderMenuPreOrderType.PreOrder,
    orderType: OrderMenuType.Online,
    isShowBack,
    autoId: genOnlineOrderAutoId(shopId),
    flatwareCount: -1,
    note: '',
    onlineDelivery: onlineDelivery === undefined ? {
      deliverFee: 0,
      distance: 0,
      fromCity: '',
      fromState: '',
      fromStreet: '',
      fromZipcode: '',
      isPickup: true,
      scheduleTime: '',
      toCity: '',
      toState: '',
      toStreet: '',
      toZipcode: ''
    } : onlineDelivery,
    onlineUser: {
      name: '',
      phoneNumber: ''
    },
    isPaid: false,
    guestCount: 1
  }
}

function buildSessionViewMenuOrder(shopId: string, menuId: string): SessionViewMenuOrder {
  return {
    shopId,
    menuId,
    preOrder: OrderMenuPreOrderType.NoPreOrder,
    orderType: OrderMenuType.ViewMenu,
    isShowBack: true
  }
}

function buildSessionTableFastOrder(
  shopId: string,
  tableId: string,
  tableName: string,
  guestCount: number,
  isDineIn: boolean,
  isShowBack: boolean,
  tableGratuities: number
): SessionTableFastModel {
  return {
    shopId,
    tableId,
    tableName,
    guestCount,
    preOrder: OrderMenuPreOrderType.PreOrder,
    orderType: OrderMenuType.TableFast,
    isDineIn,
    isShowBack,
    tableGratuities,
    autoId: genFastOrderAutoId(shopId),
    isPaid: false
  }
}

function buildSessionWaitlistOrder(shopId: string): SessionWaitlistMenuModel {
  return {
    shopId,
    preOrder: OrderMenuPreOrderType.NoPreOrder,
    orderType: OrderMenuType.WaitlistMenu,
    isShowBack: true
  }
}

function buildSessionShareMenuOrder(shopId: string, code: string): SessionShareMenuModel {
  return {
    shopId,
    preOrder: OrderMenuPreOrderType.NoPreOrder,
    orderType: OrderMenuType.ShareMenu,
    isShowBack: true,
    code
  }
}

function buildSessionDineOrder(shopId: string, autoId: string, tableId: string, tableName: string, guestCount: number,
  preOrder: OrderMenuPreOrderType, isDineIn: boolean, isShowBack: boolean, isJoinOrder: boolean,
  tableGratuities: number, isPaid: boolean): SessionDineInModel {
  return {
    shopId,
    autoId,
    tableId,
    tableName,
    guestCount,
    preOrder,
    orderType: OrderMenuType.DineIn,
    isDineIn,
    isShowBack,
    isJoinOrder,
    tableGratuities,
    isPaid
  }
}

// function buildScanToPayModel(shopId: string, autoId: string, tableId: string, tableName: string,
//   guestCount: number, isTaxFree: boolean, tax: number, gratuities: number, gratuityType: number,
//   gratuityRate: number, deliveryFee: number,
//   tableOrderType: number, tableGratuities: number, isShowBack: boolean): SessionScanToPayModel {
//   return {
//     shopId,
//     autoId,
//     tableId,
//     tableName,
//     guestCount,
//     tableOrderType,
//     preOrder: OrderMenuPreOrderType.NoPreOrder,
//     orderType: OrderMenuType.ScanToPay,
//     isShowBack,
//     tableGratuities,
//     isTaxFree,
//     tax,
//     gratuities,
//     gratuityType,
//     gratuityRate,
//     deliveryFee
//   }
// }
// buildScanToPayModel(shopId, autoId, tableId, tableName, guestCount, tableOrderType, tableGratuities, false)

function buildScanToPayModel(shopId: string, autoId: string, tableId: string,
  tableName: string, guestCount: number, tableOrderType: number, tableGratuities: number, isShowBack: boolean): SessionScanToPayModel {
  return {
    shopId,
    autoId,
    tableId,
    tableName,
    guestCount,
    tableOrderType,
    preOrder: OrderMenuPreOrderType.NoPreOrder,
    orderType: OrderMenuType.ScanToPay,
    isShowBack,
    tableGratuities,
    tax: 0,
    gratuities: 0,
    gratuityType: 0,
    deliveryFee: 0
  }
}

export {
  buildSessionMenuOrder,
  buildSessionOnlineOrder,
  buildSessionViewMenuOrder,
  buildSessionTableFastOrder,
  buildSessionWaitlistOrder,
  buildSessionShareMenuOrder,
  buildSessionDineOrder,
  buildScanToPayModel
}
